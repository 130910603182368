  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-30-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert sécheresse Marseille</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Faites appel à un Expert pour un ouvrage fissuré suite à un épisode de sécheresse à Marseille</h1>

<p>Les experts du cabinet RV Expertises interviennent pour expertiser votre ouvrage fissuré suite à un sinistre sécheresse à Marseille. </p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert sécheresse Marseille" />
</div>


<p>Nous vous aiderons à déterminer les travaux de reprise les plus adaptés à votre problématique et vous représenteront auprès de votre assurance afin d’assurer au mieux la défense de vos intérêts.</p>
<h2>Les désordres fissures suite à une sécheresse</h2>

<p>Après un été chaud et sec, vous constatez l’apparition de fissures sur votre ouvrage. </p>
<p>Les argiles constitutives du sol situé sous les fondations de votre maison sont très probablement les fautives ! </p>
<p>En effet, un épisode de sécheresse favorise le retrait des argiles. Le sol tasse et votre ouvrage, qui suite ce tassement, va fissurer de façon plus ou moins grave.</p>

<p>Dès lors, plusieurs recours existent pour trouver des solutions techniques afin de réparer de façon pérenne votre ouvrage.</p>

<h2>Que faire lorsque vous constatez des fissures après une sécheresse ?</h2>

<p>Votre contrat d’assurance prévoit la garantie Catastrophe Naturelle dès lors qu’un Arrêté de catastrophe naturelle pour retrait et gonflement des argiles, est approuvé sur votre commune ou votre arrondissement de Marseille.</p>

<p>Vous avez 10 jours pour déclarer votre sinistre à votre assurance.</p>

<p>L’assureur peut alors, en fonction des conclusions de son expert, accepter ou refuser la garantie de votre sinistre.</p>

<h2>Que faire en cas de refus de garantie de sinistre sécheresse par votre assurance ?</h2>

<p>Si votre assurance refuse la garantie de votre sinistre sécheresse pour un motif que vous jugez infondé, vous avez alors la possibilité de vous défendre en mandatant un expert d’assuré sécheresse qui va défendre vos intérêts.</p>

<p>L’expert d’assuré sécheresse est un professionnel du bâtiment indépendant, neutre et impartial.</p>

<p>l’expert sécheresse expertise votre bâtiment et détermine, dans son rapport, si la sécheresse constitue le facteur déterminant d’apparitions des fissures.</p>
<h2>L’expertise sécheresse du cabinet RV Expertises</h2>

<p>Le cabinet RV Expertises est un cabinet d'experts spécialisés dans les pathologies de la construction et l’accompagnement des assurés dans les dossiers sinistres sécheresse.</p>

<p>Nos experts mettent tout en œuvre pour mettre en place une expertise adaptée à vos besoins afin de défendre vos intérêts. </p>
<p>Nous assurons également un rôle de médiation auprès de l’assurance.</p>

<p>Si vous avez des doutes sur les motifs du refus de l’assurance, défendez-vous et faites-vous accompagner par un expert sécheresse du cabinet RV Expertises.</p>

<p>Nous défendons vos intérêts jusqu’à la juste indemnisation de votre sinistre !</p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Demandez un devis</a>
    </div>
</div>
                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-secheresse-marseille/' className='active'>Expert sécheresse Marseille</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details